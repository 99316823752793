import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import { FetchData } from './components/FetchData';

import { Counter } from './components/Counter';

import './custom.css'

class App extends Component {
    constructor() {
        super();
        this.state = {
            loading: true
        };
    }
    componentDidMount = () => {
        this.setState({ loading: false });
    }

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route exact path='/login' component={Login} />
            </Layout>
        );
    }
}
export default App;
