import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
class Login extends Component {


    constructor(props) {
        super(props);

        this.state = {
            login: '',
            pass: '',
            logined: false
        };
    }


    fetchLogin = () => {
        const cookies = new Cookies();
        if (this.state.login.length === 0) {
            alert('Введите логин');
            return;
        }
        if (this.state.pass.length === 0) {
            alert('Введите пароль');
            return;
        }
        var json = {
            login: this.state.login,
            pass: this.state.pass
        };

        fetch('WeatherForecast/Login',
            {
                method: 'POST',
                body: JSON.stringify(json),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(x => x.text()).then(x => {
                if (x.length > 2) {
                    cookies.set('LUG86rG7u6RukgURTk', x.replace('"', '').replace('"',''), { maxAge: 3600 * 48 });
                    this.setState({ logined: true });
                } else {
                    alert('Не правильный логин или пароль.')
                }
                
            });
    }



    render() {
        return (
            <div Style="display: flex;flex-direction: column;margin-top: 75px;align-items: center;">
                {this.state.logined ? <Redirect to="/" /> : <></>}
                <p Style="font-family: 'Istok Web';font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #333333;">Логин</p>
                <input Style="border: 1px solid #DADADA;width: 750px;font-family: 'Istok Web';box-sizing: border-box;border-radius: 2px;padding: 15px;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #333;" onInput={(e) => this.setState({ login: e.target.value })} type="text" />
                <p Style="font-family: 'Istok Web';font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #333333;">Пароль</p>
                <input Style="border: 1px solid #DADADA;font-family: 'Istok Web';width: 750px;box-sizing: border-box;border-radius: 2px;padding: 15px;font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #333;" type="password" onInput={(e) => this.setState({ pass: e.target.value })} />


                <button onClick={() => this.fetchLogin()} style={{ marginTop: '20px', backgroundColor: '#ef8f22', color: 'white', fontSize: '16px' }}>Войти</button>
            </div>
        );
    }
}
export default Login;

