import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Drawer from 'react-drag-drawer';
import Cookies from 'universal-cookie';

class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        var redirect = false;
        var tempC = cookies.get('LUG86rG7u6RukgURTk');
        if (tempC !== null && tempC !== undefined && tempC.length > 0) {
            redirect = false;
        } else {
            redirect = true;
        }
        this.emailEditorRef = React.createRef();
        this.state = {
            file: '',
            subject: '',
            excel: '',
            attach: [],
            sending: false,
            freshLogs: false,
            error_modal: false,
            excel_modal: false,
            logsModal: false,
            freshData: [],
            t_html: false,
            error_text: '',
            redirect: redirect,
            excelData: [],
            server: 'mail.factum-ua.com',
            port: 25,
            sender: '',
            selectedId: -1,
            mailPassword: '',
            projectList: [],
            endTable: [],
            letterLogsArr: [],
            logsData: [],
            timeout: 0
        };
    }
    logs_modal_toggle = () => {
        let { logsModal } = this.state

        this.setState({ logsModal: !logsModal })
    }
    fresh_modal_toggle = () => {
        let { freshLogs } = this.state

        this.setState({ freshLogs: !freshLogs })
    }
    error_modal_toggle = () => {
        let { error_modal } = this.state

        this.setState({ error_modal: !error_modal })
    }
    excel_modal_toggle = () => {
        let { excel_modal } = this.state

        this.setState({ excel_modal: !excel_modal })
    }
    componentDidMount = () => {
        this.fetchProjects();
        this.getAllLogs();
    }
    fetchProjects = () => {
        fetch('WeatherForecast/GetProjects').then(x => x.json()).then(x => this.setState({ projectList: x }));
    }
    letterPreview = (file) => {
        var formData = new FormData();
        formData.append('zip', file);
        fetch('WeatherForecast/PreviewLetter?html=' + this.state.t_html, {
            method: "POST",

            body: formData
        }).then(x => x.json()).then(x => this.setState({ error_text: x.html, error_modal: true  }));
    }
    getAllLogs = () => {
        fetch('WeatherForecast/GetLogsList').then(x => x.json()).then(x => this.setState({ letterLogsArr: x }));
    }
    excelPreview = (file) => {
        var formData = new FormData();
        formData.append('zip', file);
        fetch('WeatherForecast/GetPreviewData', {
            method: "POST",

            body: formData
        }).then(x => x.json()).then(x => this.setState({ excelData: x }));
    }
    validServer = () => {
        if (this.state.server.length === 0) {
            return "Введите адресс сервера";
        } else if (this.state.sender.length === 0) {
            return "Введите адресс отправителя";
        } else if (this.state.mailPassword.length === 0) {
            return "Введите пароль";
        } else {
            return "";
        }
    }
    freshFetch = () => {
        var _this = this;
        setInterval(function () { fetch('WeatherForecast/GetFreshLog').then(x => x.json()).then(x => _this.setState({ freshData: x })) }, 1000);
    }
    test = (e) => {
        if (!this.state.sending) {


            var formData = new FormData();

            var file = this.state.file;
            if (file === '' && this.state.selectedId === -1) {
                alert('Выберете файл с письмом')
                return;
            }
            if (this.state.excel === '') {
                alert('Выберете файл с данными')
                return;
            }
            if (this.state.subject === '') {
                alert('Напишите тему письма')
                return;
            }

            var validServer = this.validServer();
            if (validServer.length > 0) {
                alert(validServer);
                return;
            }

            for (var i = 0; i < this.state.attach.length; i++) {
                formData.append('attach', this.state.attach[i]);

            }
            var json = {
                server: this.state.server,
                port: this.state.port,
                sender: this.state.sender,
                password: this.state.mailPassword

            };
            formData.append('name', file.name);
            formData.append('file', file);
            formData.append('subject', this.state.subject);
            formData.append('excel', this.state.excel);
            formData.append('selectedId', this.state.selectedId);
            formData.append('timeout', this.state.timeout);
            formData.append('sendInfo', JSON.stringify(json));
            this.freshFetch();
            this.setState({ sending: true, freshLogs: true });
            fetch('WeatherForecast/TestMSG?html=' + this.state.t_html, {
                method: "POST",

                body: formData
            }).then(x => x.json()).then(x => { this.setState({ endTable: x, sending: false, freshLogs: false }); this.getAllLogs(); });
        }
        // console.log(e.target.value);
    }


    getFileNames = () => {
        var list = "";
        if (this.state.attach !== undefined) {
            Array.prototype.slice.call(this.state.attach).forEach(x => {
                list += x.name + '\n';
            });
        }

        return list;
    }
    setProject = (id, subject) => {
        this.setState({ subject: subject, selectedId: id })
    }

    render() {
        return (
            <div>
                {this.state.redirect ? <Redirect to="/login" /> : <></>}
                <Drawer
                    open={this.state.logsModal}
                    onRequestClose={this.logs_modal_toggle}>
                    <div Style="background: white;padding: 25px 25px 05px 25px;" className="test">
                        <table Style="background: #f5f5f5" className="table">
                            {this.state.logsData.map(x =>
                                <tr>
                                    <td>{x.split(';')[0]}</td>
                                    <td>{x.split(';')[1] === 'true' ? 'Отправлено' : 'Не отправлено'}</td>
                                </tr>
                            )}
                        </table>
                    </div>

                </Drawer>
                <Drawer
                    open={this.state.error_modal}
                    onRequestClose={this.error_modal_toggle}>
                    <div className="test" Style="position: absolute;top: -300px;left: -400px;">
                        <p dangerouslySetInnerHTML={{ __html: this.state.error_text }}></p>
                    </div>

                </Drawer>
                <Drawer
                    open={this.state.excel_modal}
                    onRequestClose={this.excel_modal_toggle}>
                    <div className="test">
                        <p style={{ padding: '24px' }}>
                            <table className="table">
                                {this.state.excelData.map(x =>
                                    <tr>{x.map(j =>
                                        <td>{j}</td>
                                    )}</tr>
                                )}
                            </table>
                        </p>
                    </div>
                </Drawer>
                <div style={{ display: this.state.sending ? '' : 'none', zIndex: 1, position: 'absolute', top: '15%', left: '35%', border: '1px solid rgb(37,46,56)', borderRadius: '4px', width: '450px', height: '300px', backgroundColor: 'white' }}>
                    <img src="_Loading_icon.gif" />
                </div>
                <Drawer
                    open={this.state.freshLogs}
                    onRequestClose={this.logs_modal_toggle}>
                    <div Style="background: white;padding: 25px 25px 05px 25px;max-height: 400px;overflow-y: scroll;" className="test">
                        <p>Отправлено: {this.state.freshData.length}/{this.state.excelData.length - 1}</p>
                        <table Style="background: #f5f5f5" className="table">
                            {this.state.freshData.map(x =>
                                <tr>
                                    <td>{x.split(';')[0]}</td>
                                    <td>{x.split(';')[1] === 'True' ? 'Отправлено' : 'Не отправлено'}</td>
                                    <td>{x.split(';')[2]}</td>
                                </tr>
                            )}
                        </table>
                    </div>

                </Drawer>
                <header style={{ display: 'flex', padding: '5px 0px', alignItems: 'center', backgroundColor: '#333', boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.15)', position: 'relative', top: '-8px', left: '-8px', width: 'calc(100% + 16px)' }}>
                    <img src="logo.png" style={{ width: '161px', height: '40px', marginLeft: '120px' }} />
                    <p style={{ fontFamily: 'Open sans', fontSize: '20px', color: '#EF8F22', marginLeft: '20px' }}>Email Sender</p>
                </header>
                <div style={{ display: 'flex', justifyContent: 'space-evenly' }} className="n-d-block-m">
                    <div>
                        <h3>Почта для рассылки</h3>
                        <hr />
                        <p>Сервер</p>
                        <input value={this.state.server} onInput={(e) => this.setState({ server: e.target.value })} type="text" />
                        <br />
                        <p>Порт</p>
                        <input value={this.state.port} onInput={(e) => this.setState({ port: parseInt(e.target.value) })} type="number" />
                        <br />
                        <p>Email отправителя</p>
                        <input value={this.state.sender} onInput={(e) => this.setState({ sender: e.target.value })} type="email" />
                        <br />
                        <p>Пароль электронной почты отправителя</p>
                        <input value={this.state.mailPassword} onInput={(e) => this.setState({ mailPassword: e.target.value })} type="password" />
                    </div>
                    <div>
                        <h3>Почта для рассылки</h3>
                        <hr />
                        <p>Тема письма</p>
                        <input value={this.state.subject} onInput={(e) => this.setState({ subject: e.target.value })} type="text" />
                        <br />
                        <p>Файл с письмом (zip)</p>
                        <input id="file-letter" style={{ display: 'none' }} type="file" onInput={(e) => { this.setState({ file: e.target.files[0] }); this.letterPreview(e.target.files[0]); }} />
                        <label style={{ display: this.state.file.name === undefined ? '' : 'none' }} className="file-button" for="file-letter"><p>Выберите файл</p><i>{this.state.file.name === undefined ? 'Файл не выбран' : this.state.file.name}</i></label>
                        <div style={{ display: this.state.file.name === undefined ? 'none' : 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <i style={{ fontStyle: 'normal', fontSize: '14px', fontWeight: 'normal', lineHeight: '20px', color: '#7c7c7c', fontFamily: 'Istok Web' }}>
                                {this.state.file.name === undefined ? "" : this.state.file.name} <img onClick={() => { this.setState({ file: '', error_text: '' }); document.getElementById('file-letter').value = ''; }} style={{ position: 'relative', top: '6px', cursor: 'pointer' }} src="close_24px.png" />
                            </i>
                            <p style={{ cursor: 'pointer', fontFamily: 'Istok Web', fontSize: '16px', lineHeight: '20px', color: '#F2994A', textDecorationLine: 'underline' }} onClick={() => this.error_modal_toggle()}>Смотреть превью</p>
                        </div>
                        <br />
                        <p>Контакт-лист для рассылки (xls, xlsx)</p>
                        <input id="contant-file" style={{ display: 'none' }} type="file" onInput={(e) => { this.setState({ excel: e.target.files[0] }); this.excelPreview(e.target.files[0]); }} />
                        <label style={{ display: this.state.excel.name === undefined ? '' : 'none' }} className="file-button" for="contant-file"><p>Выберите файл</p><i>{this.state.excel.name === undefined ? 'Файл не выбран' : this.state.excel.name}</i></label>
                        <div style={{ display: this.state.excel.name === undefined ? 'none' : 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <i style={{ fontStyle: 'normal', fontSize: '14px', fontWeight: 'normal', lineHeight: '20px', color: '#7c7c7c', fontFamily: 'Istok Web' }}>
                                {this.state.file.name === undefined ? "" : this.state.excel.name} <img onClick={() => this.setState({ excel: '' })} style={{ position: 'relative', top: '6px', cursor: 'pointer' }} src="close_24px.png" />
                            </i>
                            <p style={{ cursor: 'pointer', fontFamily: 'Istok Web', fontSize: '16px', lineHeight: '20px', color: '#F2994A', textDecorationLine: 'underline' }} onClick={() => this.excel_modal_toggle()}>Смотреть превью</p>
                        </div>
                        <br />
                        <p>Файлы вложения</p>
                        <input style={{ display: 'none' }} id="attach-file" type="file" multiple onInput={(e) => this.setState({ attach: e.target.files })} />
                        <label className="file-button" for="attach-file"><p>Выберите файл</p><i>{this.state.attach[0] === undefined ? 'Файл не выбран' : this.getFileNames()}</i></label>
                        <br />
                        <p>Задержка (в секундах) между отправками эмейлов</p>
                        <input type="number" min="0" value={this.state.timeout} onInput={(e) => this.setState({ timeout: parseInt(e.target.value) })} />
                        <br />
                        <label Style="font-style: normal;font-weight: normal;font-size: 16px;line-height: 23px;color: #333333;font-family: 'Istok Web';"><input type="checkbox" onInput={(e) => this.setState({ t_html: e.target.checked })} /> HTML</label>
                        <button onClick={() => this.test()}>ОТПРАВИТЬ</button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '0 120px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 style={{ fontFamily: 'Open sans', fontStyle: 'normal', fontWeight: 'bold', fontSize: '28px', lineHeight: '50px', color: '#333' }}>Отчеты о рассылках</h2>
                        <i style={{ marginTop: '10px', marginLeft: '12px', cursor: 'pointer' }}><img src="info_outline_24px.png" /></i>
                        <div style={{ backgroundImage: 'url(Union.png)', fontFamily: 'Istok Web', marginLeft: '18px', marginTop: '8px', backgroundRepeat: 'no-repeat', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px', lineHeight: '20px', color: '#333', padding: '16px 20px' }}>Информация о доставке писем в рассылках</div>
                    </div>
                    <div>
                        <table className="n-table">
                            <tr>
                                <th>ID</th>
                                <th>Название рассылки</th>
                                <th>Дата и время</th>
                                <th></th>
                            </tr>
                            {this.state.letterLogsArr.map(x =>
                                <tr>
                                    <td>{x.projectId}</td>
                                    <td>{x.subject}</td>
                                    <td>{x.date}</td>
                                    <td><button onClick={() => this.setState({ logsModal: true, logsData: x.data })}>Просмотреть отчет</button></td>
                                    <td><button onClick={() => this.setProject(x.projectId, x.subject)}>загрузить шаблон</button></td>
                                </tr>
                            )}


                        </table>
                    </div>
                </div>
            </div>
            /* <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                 <div Style="display: flex;flex-direction: column;align-items: center;margin-top: 75px;margin-right: 12px;">
                     <p style={{ width: '100%', textAlign: 'left' }}>Логи:</p>
                     {this.state.letterLogsArr.map(x =>
                         <button onClick={() => this.setState({ logsModal: true, logsData: x.data })} style={{ marginBottom: '5px' }}>{x.subject + ' ' + x.date}</button>
                     )}
                 </div>
                 <div Style="display: flex;flex-direction: column;align-items: center;margin-top: 75px;margin-right: 12px;">
                     <div className="data-block">
                         <p>Сервер</p>
                         <input type="text" value={this.state.server} onInput={(e) => this.setState({ server: e.target.value })} />
                     </div>
                     <div className="data-block">
                         <p>Порт</p>
                         <input type="text" value={this.state.port} onInput={(e) => this.setState({ port: parseInt(e.target.value) })} />
                     </div>
                     <div className="data-block">
                         <p>Email отправителя (сервис работает только с  почтовиком mail.factum-ua.com)</p>
                         <input type="text" value={this.state.sender} onInput={(e) => this.setState({ sender: e.target.value })} />
                     </div>
                     <div className="data-block">
                         <p>Пароль электронной почты отправителя</p>
                         <input type="text" value={this.state.mailPassword} onInput={(e) => this.setState({ mailPassword: e.target.value })} />
                     </div>
                 </div>
                 <div Style="display: flex;flex-direction: column;align-items: center;margin-top: 75px;margin-right: 12px;">
                     {this.state.redirect ? <Redirect to="/login" /> : <></>}
                     <Drawer
                         open={this.state.error_modal}
                         onRequestClose={this.error_modal_toggle}>
                         <div className="test">
                             <p dangerouslySetInnerHTML={{ __html: this.state.error_text }}></p>
                         </div>
 
                     </Drawer>
                     <Drawer
                         open={this.state.logsModal}
                         onRequestClose={this.logs_modal_toggle}>
                         <div Style="background: white;padding: 25px 25px 05px 25px;" className="test">
                             <table Style="background: #f5f5f5" className="table">
                                 {this.state.logsData.map(x =>
                                     <tr>
                                         <td>{x.split(';')[0]}</td>
                                         <td>{x.split(';')[1] === 'true' ? 'Отправлено' : 'Не отправлено'}</td>
                                     </tr>
                                 )}
                             </table>
                         </div>
 
                     </Drawer>
                     <Drawer
                         open={this.state.excel_modal}
                         onRequestClose={this.excel_modal_toggle}>
                         <div className="test">
                             <p style={{ padding: '24px' }}>
                                 <table className="table">
                                     {this.state.excelData.map(x =>
                                         <tr>{x.map(j =>
                                             <td>{j}</td>
                                         )}</tr>
                                     )}
                                 </table>
                             </p>
                         </div>
                     </Drawer>
                     <div style={{ display: this.state.sending ? '' : 'none', position: 'absolute', top: '35%', left: '35%', border: '1px solid rgb(37,46,56)', borderRadius: '4px', width: '450px', height: '300px', backgroundColor: 'white' }}>
                         <img src="_Loading_icon.gif"/>
                     </div>
                     <div className="data-block">
                         <p>Тема письма</p>
                         <input type="text" value={this.state.subject} onInput={(e) => this.setState({ subject: e.target.value })} />
                     </div>
                     <div style={{ display: this.state.selectedId === -1 ? '' : 'none'}} className="data-block">
                         <p>Файл с письмом(zip)</p>
                         <input type="file" onInput={(e) => { this.setState({ file: e.target.files[0] }); this.letterPreview(e.target.files[0]); }} />
                         <button style={{ display: this.state.error_text.length > 0 ? '' : 'none', width: '100%' }} onClick={() => this.error_modal_toggle()}>Показать превью</button>
                     </div>
 
                     <div className="data-block">
                         <p>Контакт-лист для рассылки (xls, xlsx)</p>
                         <input type="file" onInput={(e) => { this.setState({ excel: e.target.files[0] }); this.excelPreview(e.target.files[0]); }} />
                         <button style={{ display: this.state.excelData.length > 0 ? '' : 'none', width: '100%' }} onClick={() => this.excel_modal_toggle()}>Показать данные</button>
                     </div>
 
                     <div className="data-block">
                         <p>Файлы вложения</p>
                         <input type="file" multiple onInput={(e) => this.setState({ attach: e.target.files })} />
                         <div className="attach-block" style={{ display: this.state.attach.length > 0 ? '' : 'none' }}>
                             <p style={{ fontWeight: 'bold' }} className="special">Список файлов:</p>
                             {Array.prototype.slice.call(this.state.attach).map(x => <p>{x.name}</p>)}
                         </div>
                     </div>
                     <div className="data-block">
                         <p>Задержка(в секундах) между отправками емейлов</p>
                         <input type="text" value={this.state.timeout} onInput={(e) => this.setState({ timeout: parseInt(e.target.value) })} />
                     </div>
                     <br />
 
                     <button style={{ backgroundColor: '#ef8f22' }} onClick={() => this.test()}>Отправить</button>
                     <table className="table">
                         {this.state.endTable.map(x =>
                             <tr>
                                 <td>{x.split(';')[0]}</td>
                                 <td>{x.split(';')[1] === 'true' ? 'Отправлено' : 'Не отправлено'}</td>
                             </tr>
                             )}
                     </table>
                 </div>
                 <div Style="display: flex;flex-direction: column;align-items: center;margin-top: 75px;margin-right: 12px;">
                     <p style={{ width: '100%', textAlign: 'left' }}>Проекты:</p>
                     {this.state.projectList.map(x =>
                         <button style={{marginBottom: '5px'}} onClick={() => this.setProject(x.id, x.subject)}>{x.subject}</button>
                     )}
                 </div>
 
             </div>*/

        );
    }
}
export default Home;

